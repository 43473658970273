import Deferred from 'deferred.js';
import events from 'events.js';
import ko from 'knockout';

import user from 'accounts/models/user.js';

const pageLoad = new Deferred();
events.ready().then(pageLoad.resolve);

Promise.all([pageLoad, user.load()]).then(() => {
    for (const elem of Array.from(
        document.getElementsByClassName('js-user-nav'),
    )) {
        ko.applyBindings(user, elem);
    }
    const navbarButton = document.getElementById('navbar-dropdown');
    if (navbarButton) {
        events.listen(document.body, 'click', function (evt) {
            const clickedElem = evt.target;
            if (navbarButton.contains(clickedElem)) {
                return false;
            }
            const dropdown = document.getElementsByClassName(
                'dropdown-menu',
            )[0];
            if (dropdown && dropdown.style.display !== 'none') {
                dropdown.style.display = 'none';
                user.showDropdown(false);
            }
        });
    }
});
