import events from 'events.js';

function handleAuth(response) {
    const {FB, ga} = window;
    if (response.status === 'connected') {
        const {facebookRedirectUri, nextUrl} = document.body.dataset;
        const accessToken = FB.getAuthResponse().accessToken;
        // eslint-disable-next-line max-len
        window.location.assign(
            `${facebookRedirectUri}/?access_token=${accessToken}&next=${nextUrl}`,
        );
        FB.Event.unsubscribe('auth.authResponseChange', handleAuth);
    }
}

export default function handleLogin() {
    const {FB} = window;
    FB.Event.subscribe('auth.authResponseChange', handleAuth);
    FB.login(function () {}, {scope: 'email'});
}

(async function () {
    await events.ready();
    const {appId} = document.body.dataset;

    const fbButton = document.getElementById('facebook-button');
    if (fbButton) {
        events.listen(fbButton, 'click', handleLogin);
    }

    window.fbAsyncInit = function () {
        const {FB} = window;
        FB.init({
            appId,
            status: false, // check login status
            // enable cookies to allow the server to access the session
            cookie: true,
            xfbml: true, // parse XFBML
            // Find latest version at
            // https://developers.facebook.com/docs/graph-api
            version: 'v6.0',
        });
    };

    (function (d) {
        const id = 'facebook-jssdk';
        const ref = d.getElementsByTagName('script')[0];
        if (d.getElementById(id)) {
            return;
        }
        const js = d.createElement('script');
        js.id = id;
        js.async = true;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        ref.parentNode.insertBefore(js, ref);
    })(document);
})();
