import events from 'events.js';
import http from 'http.js';
import ko from 'knockout';
import query from 'query.js';

class SearchResult {
    constructor(mgr, minimal) {
        this.mgr = mgr;
        this.text = ko.observable('');
        this.sortText = ko.observable('');
        this.type = ko.observable('');
        this.url = ko.observable('');
        this.avatar = ko.observable('');
        this.extra = ko.observable();
        this.icon = ko.observable();
        this.colour = ko.observable();
        this.selectable = ko.observable(true);

        this.prefillText = ko.pureComputed(() => this.text());

        this.template = ko.pureComputed(
            () =>
                this.type().toLowerCase() +
                (minimal ? '-result-minimal' : '-result'),
        );

        this.action = this.action.bind(this);
    }

    load(data) {
        this.sortText(data.sort_text);
        this.text(data.text);
        this.type(data.object_type);
        this.url(data.url);
        this.avatar(data.avatar);
        this.extra(data.extra);
        this.icon(this.mgr.filterTypes[data.object_type].icon);
        this.colour(this.mgr.filterTypes[data.object_type].colour);
    }

    // Performed when selected and user hits enter
    action() {
        location.assign(this.url());
    }
}

class SearchTitle {
    constructor(mgr, title, icon, colour, facet, showMore) {
        this.mgr = mgr;
        this.text = ko.observable(title);
        this.icon = ko.observable(icon);
        this.colour = ko.observable(colour);
        this.type = ko.observable('search-title');
        this.template = ko.observable('search-title');
        this.selectable = ko.observable(false);
        this.facet = facet;
        this.showMore = ko.observable(showMore);

        this.action = this.action.bind(this);
    }

    action() {
        const query = encodeURIComponent(this.mgr.typedQuery()).replace(
            /%20/g,
            '+',
        );
        location.assign(`${this.mgr.baseUrl}?q=${query}&filter=${this.facet}`);
    }
}

class NoResults {
    constructor(mgr) {
        this.mgr = mgr;
        this.text = ko.observable(`No results for "${mgr.typedQuery()}"`);
        this.type = ko.observable('no-results');
        this.template = ko.observable('no-results-result');
        this.selectable = ko.observable(false);
    }

    action() {}
}

class MoreResults {
    constructor(mgr) {
        this.mgr = mgr;
        this.text = ko.observable(
            `Show more results for "${mgr.typedQuery()}" >`,
        );
        this.type = ko.observable('more-results');
        this.template = ko.observable('more-results-result');
        this.selectable = ko.observable(true);

        this.prefillText = ko.pureComputed(function () {
            return mgr.typedQuery();
        });

        this.action = this.action.bind(this);
    }

    action() {
        const query = encodeURIComponent(this.mgr.typedQuery()).replace(
            /%20/g,
            '+',
        );
        if (this.mgr.isSearchResults()) {
            this.mgr.loadMore();
        } else {
            location.assign(`${this.mgr.baseUrl}?q=${query}`);
        }
    }
}

const DEFAULT_FILTER_TYPES = {
    'total': {
        title: 'All Results',
        icon: 'fa-search',
    },
    'threads': {
        title: 'Q&A',
        icon: 'fa-comment-o',
        colour: 'light-purple',
    },
    'practice_locations': {
        title: 'Practice Locations',
        icon: 'fa-map-marker',
        colour: 'light-orange',
    },
    'practice_groups': {
        title: 'Practices',
        icon: 'fa-hospital-o',
        colour: 'light-orange',
    },
    'factsheets': {
        title: 'Fact Sheets',
        icon: 'fa-file-text-o',
        colour: 'light-cyan',
    },
    'communities': {
        title: 'Topic Pages',
        icon: 'fa-bookmark-o',
        colour: 'light-red',
    },
    'organisation_profiles': {
        title: 'Health Organisation',
        icon: 'fa-building-o',
        colour: 'baby-blue',
    },
    'userprofiles': {
        title: 'Health Professionals',
        icon: 'fa-user-md',
        colour: 'light-green',
    },
    'localities': {
        title: 'Specialty Directory',
        icon: 'fa-map-marker',
        colour: 'yellow',
    },
    'hospital_localities': {
        title: 'Hospital Directory',
        icon: 'fa-map-marker',
        colour: 'light-orange',
    },
    'hospitals': {
        title: 'Hospitals',
        icon: 'fa-plus-square',
        colour: 'light-orange',
    },
    'special_interests': {
        title: 'Special Interests',
        icon: 'fa-search',
        colour: 'light-orange',
    },
    'specialties_in_localities': {
        title: 'Directory Results',
        icon: 'fa-map-marker',
        colour: 'light-orange',
    },
    'specialties': {
        title: 'Specialties',
        icon: 'fa-stethoscope',
        colour: 'yellow',
    },
};

export class Filter {
    constructor(name, type, icon) {
        this.name = name;
        this.type = type;
        this.icon = icon;
        this.numShown = ko.observable(10);
        this.iconClass = ko.observable(`fa fa-fw text-muted ${icon}`);
    }
}

export default class SearchManager {
    constructor(typeLimit) {
        // TODO convert to static properties when our babel buildchain supports
        this.SORT_RELEVANCY = 'relevancy';
        this.SORT_ALPHABETICAL = 'alphabetical';

        this.filterTypes = DEFAULT_FILTER_TYPES;
        this.baseUrl = '/search/';
        this.title = ko.observable('All Results');
        this.searchUrl = '/api/search/v1/all/';
        this.query = ko.observable('').extend({
            'rateLimit': 200,
            'method': 'notifyWhenChangesStop',
        });
        this.typedQuery = ko.observable();
        this.autoSetQuery = false;
        this.isReferrals = document.body?.dataset?.isReferrals;
        this.results = ko.observableArray();
        this.selectedIndex = ko.observable(-1);
        this.searching = ko.observable(false);
        this.sortCriteria = ko.observable(this.SORT_RELEVANCY);
        this.filters = ko.observableArray();
        const filterType = this.filterTypes.total;
        this.defaultFilter = new Filter(
            filterType.title,
            'total',
            filterType.icon,
        );
        // Set default filter
        this.currentFilter = ko.observable(this.defaultFilter);
        this.currentFilter.subscribe(() => {
            this.page = 1;
            this.search(null, true, false);
        });
        this.pageLoading = ko.observable(true);
        this.totalCount = ko.observable();
        this.isSearchResults = ko.observable(false);
        this.page = 1;
        this.totals = {};
        this.elems = [];
        this.numberOfSearches = 0;
        this.limit = typeLimit || 10;
        this.typeLimit = ko.observable(typeLimit);
        this.facets = [];
        this.sortOrder = [
            'userprofiles',
            'factsheets',
            'hospitals',
            'practice_groups',
            'communities',
            'specialties',
            'special_interests',
            'organisation_profiles',
            'specialties_in_localities',
            'threads',
            'localities',
            'hospital_localities',
        ];
        this.inputSelected = ko.observable(false);
        this.showMore = false;

        this.sortedResults = ko.pureComputed(() => {
            if (this.typeLimit()) {
                if (
                    !this.results().length ||
                    this.results()[0].type() === 'no-results'
                ) {
                    return this.results();
                }

                const results = [];
                const data = {};

                let calcLimit = Math.min(
                    this.typeLimit(),
                    this.results().length,
                );
                const calcFacets = this.facets.slice();
                while (calcLimit > 0) {
                    const toRemove = [];
                    const startingCalcLimit = calcLimit;
                    for (const facet of calcFacets) {
                        if (!data[facet.type]) {
                            data[facet.type] = {
                                count: facet.count,
                                length: 0,
                                results: [],
                            };
                        }
                        const index = calcFacets.indexOf(facet);
                        const length = calcFacets.length - index;
                        let amount = Math.ceil(calcLimit / length);
                        if (amount >= facet.count - data[facet.type].length) {
                            amount = facet.count - data[facet.type].length;
                            toRemove.push(facet);
                        }
                        data[facet.type].length += amount;
                        calcLimit -= amount;
                    }
                    for (const facet of toRemove) {
                        calcFacets.splice(calcFacets.indexOf(facet), 1);
                    }
                    // fail safe if calcLimit never reaches 0
                    if (startingCalcLimit === calcLimit) {
                        break;
                    }
                }

                let amountAdded = 0;
                for (const result of this.results()) {
                    const d = data[result.type()];
                    if (!d) {
                        continue;
                    }
                    if (d.results.length < d.length) {
                        d.results.push(result);
                        amountAdded++;
                    }
                    if (amountAdded >= this.typeLimit()) {
                        break;
                    }
                }
                for (const orderedFacet of this.sortOrder) {
                    for (const facet of this.facets) {
                        if (orderedFacet !== facet.type) {
                            continue;
                        }
                        if (!data[facet.type].length) {
                            continue;
                        }
                        const facetType = data[facet.type];
                        const facetLookup = this.filterTypes[facet.type];
                        const title = facetLookup.title;
                        const icon = facetLookup.icon;
                        const colour = facetLookup.colour;
                        const showMore =
                            facetType.count > facetType.length ||
                            facet.show_more;
                        results.push(
                            new SearchTitle(
                                this,
                                title,
                                icon,
                                colour,
                                facet.type,
                                showMore,
                            ),
                        );
                        results.push(...facetType.results);
                    }
                }

                if (
                    this.results().length > this.typeLimit() ||
                    this.showMore
                ) {
                    results.push(new MoreResults(this));
                }

                return results;
            } else {
                if (this.sortCriteria() === this.SORT_RELEVANCY) {
                    return this.results();
                } else if (this.sortCriteria() === this.SORT_ALPHABETICAL) {
                    return this.results()
                        .slice(0)
                        .sort(function (a, b) {
                            if (a.sortText && !b.sortText) {
                                return -1;
                            } else if (b.sortText && !a.sortText) {
                                return 1;
                            }
                            if (
                                a.sortText().toLowerCase() >
                                b.sortText().toLowerCase()
                            ) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });
                }
                return [];
            }
        });

        // Track what user actually typed as we may interfere with query text
        this.query.subscribe((val) => {
            this.page = 1;
            if (this.autoSetQuery) {
                // If flagged not to update, clean up flag
                this.autoSetQuery = false;
            } else {
                this.typedQuery(val);
            }
        });

        this.selected = ko.pureComputed(() => {
            if (this.selectedIndex() > -1) {
                return this.sortedResults.peek()[this.selectedIndex()];
            }
        });

        // When selection changes, pre-fill text box(es) with result
        this.selected.subscribe((selection) => {
            if (!selection) {
                return;
            }
            const text = selection.prefillText();
            this.autoSetQuery = true; // flag to typedQuery sub not to update
            this.query(text);
            // If this begins with what user typed, then select text
            // following it, so user can keep typing onto their result
            const typedQuery = this.typedQuery();
            if (
                text.substring(0, typedQuery.length).toLowerCase() !==
                typedQuery.toLowerCase()
            ) {
                return;
            }
            for (const e of this.elems) {
                if (!e) {
                    break;
                }
                const elem = e.querySelector('input[name=q]');
                if (!elem || !elem.setSelectionRange) {
                    break;
                }
                // Since we don't get notified when value actually updates
                // and we want to select only once that happens, poll on the
                // value before selecting
                const interval = setInterval(function () {
                    if (elem.value === text) {
                        elem.setSelectionRange(typedQuery.length, text.length);
                        clearInterval(interval);
                    }
                }, 20);
            }
        });

        this.hasMore = ko.computed(
            () => this.sortedResults().length < this.totalCount(),
        );

        this.typedQuery.subscribe(() => this.search(null, true, true));

        // catch all clicks that haven't been cancelled from text input
        // & close dropdown
        // because this happens on html, any lower level click handlers happen
        // sooner
        // This means that actions are guaranteed to have executed before we
        // close the dropdown
        const search = document.getElementsByClassName('js-query')[0];

        events.listen(document.body, 'click', (e) => {
            if (!search) {
                return;
            }
            const elems = Array.from(
                document.getElementsByClassName('js-ignore-dropdown-close'),
            );
            for (const elem of elems) {
                if (elem.contains(e.target)) {
                    search.focus();
                    return;
                }
            }
            this.close();
        });

        if (search) {
            events.listen(search, 'focus', () => {
                if (this.typedQuery() && !this.sortedResults().length) {
                    this.search(null, true, true);
                }
            });
        }

        this.keydown = this.keydown.bind(this);
        this.select = this.select.bind(this);
    }

    loadMore() {
        const lastResult = this.results()[this.results().length - 1];
        if (lastResult.type() === 'more-results') {
            this.results.remove(lastResult);
        }
        this.page += 1;
        this.search(null);
    }

    async search(q, clearResults, updateFilter) {
        // cancel previous request if still going
        if (this.searching()) {
            this.req.abort();
        }
        if (q) {
            this.query(q);
        }
        if (this.query().length < 3) {
            this.results([]);
            this.filters([]);
            this.searching(false);
            return;
        }
        if (this.isSearchResults()) {
            if (history.pushState) {
                const query = encodeURIComponent(this.query()).replace(
                    /%20/g,
                    '+',
                );
                history.pushState(
                    {'query': this.query()},
                    document.title,
                    `${location.origin}${location.pathname}?q=${query}`,
                );
            }
        }
        this.searching(true);

        const data = {
            q: this.query(),
            limit: this.typeLimit()
                ? this.limit * this.sortOrder.length
                : this.limit,
        };

        if (this.typeLimit()) {
            // eslint-disable-next-line camelcase
            data.type_limit = this.limit;
        }

        if (this.page !== 1) {
            data.page = this.page;
        }
        const filterType = this.currentFilter().type;
        if (this.types) {
            data.types = this.types;
        }
        if (filterType !== 'total') {
            data.types = filterType;
        }
        if (this.isReferrals === 'True') {
            data.show = 'referrals';
            data.show_all_practitioners = 'true';
        }
        this.req = http.get({url: this.searchUrl, data});
        const respData = await this.req;
        let results;

        const facets = [];
        let items = respData.facets;
        if (!(items instanceof Array)) {
            items = Object.keys(respData.facets);
        }
        for (const sortedFacet of this.sortOrder) {
            for (const facet of items) {
                if (sortedFacet === facet.type) {
                    facets.push(facet);
                }
            }
        }
        this.facets = facets;

        if (clearResults) {
            results = [];
        } else {
            results = this.results();
        }

        this.selectedIndex(-1);
        this.totalCount(respData.count);

        if (typeof this.totals[this.query()] === 'undefined') {
            this.totals[this.query()] = {};
        }

        if (
            this.isSearchResults() &&
            respData.results.length === 1 &&
            query.pathSegment(0) === 'factsheets' &&
            this.numberOfSearches === 0
        ) {
            location = respData.results[0].url + location.search;
        }
        this.numberOfSearches++;

        for (const r of respData.results) {
            const result = new SearchResult(this, this.typeLimit());
            result.load(r);
            results.push(result);
        }

        if (updateFilter) {
            const filters = [];
            if (Object.keys(respData.facets).length) {
                for (const f of respData.facets) {
                    const filterType = this.filterTypes[f.type];
                    if (typeof filterType !== 'undefined') {
                        filters.push(
                            new Filter(
                                filterType.title,
                                f.type,
                                filterType.icon,
                            ),
                        );
                    }
                }
            }
            // If we didn't restrict to a single category, but only got one
            // back, don't show 'show all results'
            if (
                filters.length > 1 ||
                this.currentFilter() !== this.defaultFilter
            ) {
                filters.unshift(this.defaultFilter);
            }
            this.filters(filters);
        }

        this.showMore = respData.show_more;

        if (respData.show_more && !this.typeLimit()) {
            results.push(new MoreResults(this));
        }

        if (!respData.results.length) {
            results.push(new NoResults(this));
        }

        this.results(results);
        this.searching(false);
        this.pageLoading(false);
    }

    // Close the dropdown, but don't clear the text
    close() {
        if (this.isSearchResults()) {
            return;
        }
        this.searching(false);
        setTimeout(() => {
            this.results([]);
            this.selectedIndex(-1);
        });
    }

    keydown(_, event) {
        const key = event.key || event.keyCode || event.which;
        if (!this.results().length) {
            return true;
        }
        if (key === 38 || key === 'Up' || key === 'ArrowUp') {
            this.select(this.selectedIndex() - 1);
            return;
        } else if (key === 40 || key === 'Down' || key === 'ArrowDown') {
            this.select(this.selectedIndex() + 1);
            return;
        } else if (key === 27 || key === 'Esc') {
            // Close dropdown on escape
            this.close();
        }
        return true;
    }

    select(index) {
        index = Math.max(Math.min(index, this.sortedResults().length - 1), 0);
        if (this.sortedResults()[index].selectable()) {
            this.selectedIndex(index);
        } else {
            const i = index + (index > this.selectedIndex() ? 1 : -1);
            if (i >= 0 && i < this.sortedResults().length) {
                this.select(i);
            }
        }
    }

    action() {
        if (this.selected()) {
            this.selected().action();
            return;
        }
        return true;
    }
}
