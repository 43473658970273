import events from 'events.js';
import ko from 'knockout';
import SearchManager from 'search/search.js';

const DESKTOP_TYPE_LIMIT = 10;
const MOBILE_TYPE_LIMIT = 5;

(async function () {
    await events.ready();
    const mgr = new SearchManager(DESKTOP_TYPE_LIMIT);

    const search = document.getElementsByClassName('js-query')[0];
    if (!search) {
        return;
    }
    function onFocus() {
        const offset =
            search.getBoundingClientRect().top +
            (document.body.scrollTop || document.documentElement.scrollTop);
        document.body.scrollTop = document.documentElement.scrollTop = offset;
    }

    if (matchMedia) {
        const mq = window.matchMedia('(min-width: 768px)');
        mq.addListener(widthChange);
        widthChange(mq);
    }

    function widthChange(mq) {
        if (mq.matches) {
            mgr.typeLimit(DESKTOP_TYPE_LIMIT);
            events.unlisten(search, 'focus', onFocus);
        } else {
            mgr.typeLimit(MOBILE_TYPE_LIMIT);
            events.listen(search, 'focus', onFocus);
        }
    }

    let existingQuery;

    for (const elem of [
        ...document.getElementsByClassName('js-autocomplete'),
    ]) {
        ko.applyBindings(mgr, elem);
        mgr.elems.push(elem);
        const query = elem.getElementsByClassName('js-query')[0];
        if (query && query.value) {
            existingQuery = query.value;
        }
    }

    if (existingQuery) {
        mgr.query(existingQuery);
    }
})();
