import events from 'events.js';
import http from 'http.js';
import ko from 'knockout';
import user from 'accounts/models/user.js';

import 'ko/bindings/stop_binding.js';

class VerificationManager {
    constructor() {
        this.email = ko.observable('').extend({
            email: true,
            required: true,
        });
        this.error = ko.observable(false);
        this.isVerified = ko.observable(false);
        this.sendVerificationScreen = ko.observable(true);
        this.changeEmailScreen = ko.observable(false);
        this.verificationSent = ko.observable(false);
        this.errors = ko.validation.group(this);
        this.sending = ko.observable(false);
    }

    setChangeEmailScreen() {
        this.changeEmailScreen(true);
        this.sendVerificationScreen(false);
    }

    hideForm() {
        this.changeEmailScreen(false);
        this.sendVerificationScreen(false);
    }

    async load() {
        await user.load();

        // Only run for authenticated users
        if (!user.id()) {
            return;
        }

        const url = '/api/base/v1/email-verification/';
        const data = await http.get({url});
        await events.ready();
        const isConfirmEmail =
            location.pathname === '/accounts/confirm-email/';
        this.isVerified(
            (data.email && !data.confirmation_email) || isConfirmEmail,
        );
        if (data.confirmation_email) {
            this.email(data.confirmation_email);
        }

        const elem = document.getElementById('email-verification');
        if (!elem) {
            return;
        }
        ko.applyBindings(this, elem);
    }

    async resend() {
        if (this.sending()) {
            return;
        }

        if (this.errors().length) {
            this.errors.showAllMessages();
            return;
        }

        this.sending(true);

        try {
            const url = '/api/base/v1/email/';
            const data = {
                email: this.email(),
            };
            await http.post({url, data});
            this.hideForm();
            this.verificationSent(true);
        } catch (ex) {
            this.hideForm();
            this.error(true);
        } finally {
            this.sending(false);
        }
    }
}

new VerificationManager().load();
