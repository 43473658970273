export default class Deferred {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.status = 'pending';
        this.promise.then(
            () => {
                this.status = 'resolved';
            },
            () => {
                this.status = 'rejected';
            },
        );
    }

    then(...args) {
        return this.promise.then(...args);
    }

    catch(...args) {
        return this.promise.catch(...args);
    }
}
